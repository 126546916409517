import _styled2 from "styled-components";
import _styled from "styled-components";
import React, { useCallback, useMemo, useState } from "react";
import { View } from "react-native";
import CpAccountMenu from "components/app/CpAccountMenu";
import PrActionButton from "components/primitives/PrActionButton";
import { ActionButtonUserType } from "components/primitives/PrActionButton/PrActionButton";
import useImdSession from "hooks/useImdSession";
import useScreenLayout from "hooks/useScreenLayout";
import Config from "services/Config";
import { AppName } from "services/Constants";
import { css, styles } from "services/Theme";
import { iconSizes, spacing } from "services/Theme/common"; // should this come from backend?

const defaultMobileQuickActions = ["EDUCATE", "PACKAGES", "PICK_LANGUAGE_AND_REGION"];
const defaultQuickActions = ["EDUCATE", "PACKAGES", "SHORTCUTS", "MODE", "PICK_LANGUAGE_AND_REGION"];
const defaultMenuActions = ["SHORTCUTS", "EDUCATE", "PACKAGES", "CHANGE_CLINIC", "MANAGE_ACCOUNT", "HELP", "SIGN_OUT", "MODE"];
const defaultPublicActions = ["IMD_FOR_PROFESSIONALS", "PICK_LANGUAGE_AND_REGION", "HELP", "MODE"];
const localStyles = {
  invisible: css`
    visibility: hidden;
  `,
  paddingLeft: css`
    padding-left: ${({
    theme
  }) => theme.spacing.triple}px;
  `,
  paddingRight: css`
    padding-right: ${({
    theme
  }) => theme.spacing.triple}px;
  `
};

/**
 * Renders the quick actions + account menu section of the header.
 */
const CpHeaderActions = ({
  position
}) => {
  const {
    isNarrowScreen,
    isWideScreen
  } = useScreenLayout();
  const {
    appConfig
  } = useImdSession();
  const headerConfig = useMemo(() => {
    return appConfig?.appHeader;
  }, [appConfig?.appHeader]);
  const menuActions = useMemo(() => {
    return headerConfig?.menuActions || null;
  }, [headerConfig?.menuActions]);
  const [maxWidth, setMaxWidth] = useState(0);
  const handleLayout = useCallback(({
    nativeEvent: {
      layout
    }
  }) => {
    setMaxWidth(layout.width);
  }, []); // If it meets any of these 3 conditions, we'll add it to our account menu:
  // 1. It's a common action
  // 2. It's a menu action
  // 3. It's a quick action but it didn't have enough space to fit in the quick action bar

  const calculateMenuActions = useCallback(actions => {
    if (actions) {
      const hasModeAction = actions.some(action => action.actionType === "MODE");
      const mappedActions = actions.filter(action => action.actionPlacement === "MENU_ACTION" || action.actionPlacement === "COMMON_ACTION").filter(action => action.actionType !== "MODE").sort((a, b) => (a.rank || Infinity) - (b.rank || Infinity)).map(action => action.actionType);

      if (hasModeAction) {
        mappedActions.push("MODE");
      }

      return mappedActions;
    }

    return [];
  }, []);
  const headerActions = useMemo(() => {
    if (!menuActions) {
      return {
        menu: defaultMenuActions,
        quickActions: isWideScreen ? defaultQuickActions : defaultMobileQuickActions
      };
    }

    const allQuickActions = [...menuActions].filter(action => action.actionPlacement === "COMMON_ACTION").sort((a, b) => (a.rank || Infinity) - (b.rank || Infinity)).map(action => {
      return action.actionType;
    });
    const quickActions = [];
    let quickActionsSpaceTaken = 0;
    const actionTypes = (appConfig?.appHeader?.menuActions || []).map(action => action.actionType);
    const spaceActionButtonsTake = {}; // Default button size (for buttons with <=4 characters)

    const defaultButtonSize = iconSizes.medium + spacing.double + spacing.single;
    actionTypes.forEach(action => {
      // we have to override these two buttons since their name is longer than their text
      const actionName = action === "PICK_LANGUAGE_AND_REGION" || action === "PICK_LANGUAGE" ? "EN" : action; // we'll give 8 pixels of space for each character on the button
      // it shouldn't apply to buttons with <= 4 characters

      const actionNameLength = actionName.length > 4 ? actionName.length - 4 : 0;
      const characterPixelLength = actionNameLength * 8;
      spaceActionButtonsTake[action] = defaultButtonSize + characterPixelLength;
    });

    for (let i = 0; i < allQuickActions.length; i++) {
      const nextButtonSpaceTaken = spaceActionButtonsTake[allQuickActions[i]];

      if (!nextButtonSpaceTaken) {
        return {
          menu: calculateMenuActions(menuActions),
          quickActions
        };
      } // buffer for the space account menu takes:


      const accountMenuBuffer = isWideScreen ? 100 : 50;

      if (nextButtonSpaceTaken + quickActionsSpaceTaken > maxWidth - accountMenuBuffer) {
        return {
          menu: calculateMenuActions(menuActions),
          quickActions
        };
      }

      quickActions.push(allQuickActions[i]);
      quickActionsSpaceTaken += nextButtonSpaceTaken;
    }

    return {
      menu: calculateMenuActions(menuActions),
      quickActions
    };
  }, [menuActions, appConfig?.appHeader?.menuActions, calculateMenuActions, isWideScreen, maxWidth]);
  const publicAppButtons = useMemo(() => {
    if (isNarrowScreen) {
      return {
        menu: defaultPublicActions,
        quickActions: []
      };
    } else {
      return {
        menu: [],
        quickActions: defaultPublicActions
      };
    }
  }, [isNarrowScreen]);
  return <_StyledView onLayout={handleLayout} $_css={[styles.navHeaderStyles.row1ButtonContainer, position === "LEFT" ? styles.rowReverse : undefined, position === "LEFT" ? localStyles.paddingRight : localStyles.paddingLeft, styles.justifyFlexEnd, styles.flexSingle]}>
      {
      /* Action Buttons */
    }
      <_StyledView2 $_css2={styles.row}>
        {headerActions && (Config.APP_NAME === AppName.ImdPublic ? publicAppButtons.quickActions : headerActions.quickActions).map(buttonName => {
        return <PrActionButton actionButton={buttonName} key={buttonName} type={ActionButtonUserType.NavHeader} />;
      })}
      </_StyledView2>

      {
      /* Account Menu */
    }
      {!headerConfig?.hideMenu && <CpAccountMenu menuActions={Config.APP_NAME === AppName.ImdPublic ? publicAppButtons.menu : headerActions.menu} position={position} />}
    </_StyledView>;
};

export default CpHeaderActions;

var _StyledView = _styled(View).withConfig({
  displayName: "CpHeaderActions___StyledView",
  componentId: "sc-hdw6yi-0"
})(["", ""], p => p.$_css);

var _StyledView2 = _styled(View).withConfig({
  displayName: "CpHeaderActions___StyledView2",
  componentId: "sc-hdw6yi-1"
})(["", ""], p => p.$_css2);