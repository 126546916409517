var _QlTopicSubtree_resourceNodeTreeQuery, _QlTopicSubtree_resourceNode;

import CtGraphqlQuery from "components/containers/CtGraphqlQuery";
import React, { useCallback } from "react";
import { graphql } from "react-relay";
import Log from "services/Log";
import SortNodeChildren from "../services/SortNodeChildren";

/**
 * Used to fetch information about a subtree of a resource node tree
 */
const QlTopicSubtreeQuery = _QlTopicSubtree_resourceNodeTreeQuery !== void 0 ? _QlTopicSubtree_resourceNodeTreeQuery : _QlTopicSubtree_resourceNodeTreeQuery = require("./__generated__/QlTopicSubtree_resourceNodeTreeQuery.graphql");
export const QlTopicSubtreeFragment = _QlTopicSubtree_resourceNode !== void 0 ? _QlTopicSubtree_resourceNode : _QlTopicSubtree_resourceNode = require("./__generated__/QlTopicSubtree_resourceNode.graphql"); // A record returned by the QlTopicSubtree parser

const QlTopicSubtree = ({
  children,
  variables
}) => {
  // This is called each time the response changes. It should return the root node of the subtree to be rendered.
  const processResponse = useCallback(response => {
    // The node is the tree root. If there's no node, there's no tree root
    if (!response?.node?._id) return undefined;
    const rootNode = {
      children: [],
      fragmentKey: response.node,
      id: response.node._id,
      isSectionNode: true,
      names: response.node.names,
      pinSelfToBottom: false,
      pinSelfToTop: false,
      rank: 0,
      shouldSortChildrenByRank: response.node.shouldSortChildrenByRank ?? false,
      type: ""
    }; // Small data store used to build the tree

    const nodeById = {
      [response.node._id]: rootNode
    }; // Add the descendants to the tree

    response.node.descendants?.forEach(descendantNode => {
      if (!descendantNode) return; // Create, or fetch the node record from the data store

      const currentNode = {
        children: nodeById[descendantNode._id]?.children ?? [],
        fragmentKey: descendantNode,
        // Store the data needed to render the node
        id: descendantNode._id,
        isSectionNode: descendantNode.isSectionNode,
        // The parent pre-initializer sets the `isSectionNode` to `true`. This ensures the value matches the backend response.
        names: descendantNode.names,
        pinSelfToBottom: descendantNode.pinSelfToBottom,
        pinSelfToTop: descendantNode.pinSelfToTop,
        rank: descendantNode.rank,
        shouldSortChildrenByRank: descendantNode.shouldSortChildrenByRank,
        type: descendantNode.type
      };
      nodeById[descendantNode._id] = currentNode; // Asking the parent, creating a placeholder if it hasn't been populated yet

      if (descendantNode.parentId) {
        if (!nodeById[descendantNode.parentId]) {
          nodeById[descendantNode.parentId] = {
            children: []
          };
        }

        currentNode.parent = nodeById[descendantNode.parentId];
      }

      currentNode.parent?.children?.push(currentNode);
    }); // Alert if a fragment key failed to be populated

    Object.values(nodeById).forEach(nodeRecord => {
      if (!nodeRecord.fragmentKey) {
        Log.error(`Node ${nodeRecord.id} is missing response info, which should not happen. This can cause missing data and render errors..`);
      }
    });
    Object.values(nodeById).forEach(treeNode => {
      if (treeNode.children) {
        SortNodeChildren(treeNode.children, treeNode.shouldSortChildrenByRank);
      }
    });
    return rootNode;
  }, []);
  return <CtGraphqlQuery process={processResponse} query={QlTopicSubtreeQuery} variables={variables}>
      {children}
    </CtGraphqlQuery>;
};

export default QlTopicSubtree;