import { useIntl } from "react-intl";
import hasKey from "helpers/hasKey";
import translations from "translations";
/**
 * An object that combines a react-intl MessageDescriptor and its interpolation values.
 */

export const backendSupportedLocales = ["en", "es", "fr"];
export const backendSupportedLocaleNames = {
  en: "English",
  es: "Español",
  fr: "Français"
};
/**
 * Returns true if a value looks like a MessageBundle
 */

export function isMessageBundle(value) {
  return hasKey(value, "message");
}
/**
 * Returns true if a value looks like a MessageDescriptor
 */

export const isMessageDescriptor = value => {
  return hasKey(value, "id");
};
/**
 * Returns the best matching backend locale for the current locale.
 */

export const useBackendSupportedLocale = () => {
  const {
    locale
  } = useIntl();
  const language = locale.split("-")[0];
  return backendSupportedLocales.find(backendLocale => backendLocale === language) || "en";
};

/**
 * Returns an array of translations for select options
 */
export const useMapOptionsTranslations = (type, options = {}) => {
  const {
    formatMessage
  } = useIntl();
  return Object.keys(translations[type]).map(key => ({
    name: formatMessage(translations[type][key]),
    value: options.valueAsTranslation ? formatMessage(translations[type][key]) : key
  }));
};
/**
 * Returns the alphabet of the current language, if the language has an alphabet, or undefined if a language is not alphabetical
 */

export const useCurrentAlphabet = () => {
  const {
    locale
  } = useIntl();
  const language = locale.split("-")[0];

  switch (language) {
    case "en":
      return ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"];

    case "es":
      return ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "ñ", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"];

    case "fr":
      return ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"];

    default:
      return undefined;
  }
};