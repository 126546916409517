import { cancelable } from "cancelable-promise";
import React, { createContext, memo, useCallback, useEffect, useMemo, useState } from "react";
import { View } from "react-native";
import CpAppLoading from "components/app/CpAppLoading";
import CpAppLoadingError from "components/common/CpAppLoadingError";
import useAppParams from "hooks/useAppParams";
import usePersistentStorage from "hooks/usePersistentStorage";
import { useIntl } from "react-intl";
import Config from "services/Config";
import { makeBackend } from "services/ImdBackend";
import Log from "services/Log";
/**
 * These headers are saved to a persistent store between sessions
 */

/**
 * Get the initial backend, pointed to the auto-resolving API URL
 */
export const regionAgnosticBackendState = makeBackend();
/**
 * The backend context type
 */

/**
 * The actual backend context
 */
export const AppBackendContext = createContext(undefined);
/**
 * The provider that manage the axios instance used to connect to the API.
 * Every time it changes relay must restart.
 */

const CxAppBackend = ({
  children
}) => {
  const {
    stringParams: {
      kiosk_id: kioskId,
      location_id: kioskLocationId,
      test_api_domain_name: testApiDomainName
    },
    url
  } = useAppParams(); // The app should always send request for the currently scoped language

  const {
    locale
  } = useIntl(); // IMPORTANT: CxAppBackend is special because it's at the top level of the app, and it handles initialization.
  // Avoid this fetch pattern in other places in the app. Instead prefer to use the `QlQueryName` pattern.

  const [fetchState, setFetchState] = useState({
    busy: false,
    fetched: false
  }); // When the app starts it should use the region agnostic backend to geocode itself

  const [backendState, setBackendState] = useState(regionAgnosticBackendState); // After geocoding is done the results will be available here

  const [whereamiResult, setWhereamiResult] = useState(undefined); // Logic to manage persistent headers. These are be updated in CxImdSession

  const [membershipId] = usePersistentStorage("membershipId");
  const [organizationId] = usePersistentStorage("organizationId"); // Logic to manage session headers

  const [sessionHeaders, setSessionHeaders] = useState({});
  const setSessionHeader = useCallback((key, value) => {
    setSessionHeaders(currentSessionHeaders => currentSessionHeaders[key] === value ? currentSessionHeaders : { ...currentSessionHeaders,
      [key]: value
    });
  }, []); // Keep the backend headers up to date with the persistent and session headers

  useEffect(() => {
    backendState.setBackendHeaders({ // Persistent Headers. These carry over between refreshes
      ...(membershipId ? {
        "Imdhealth-Membership": membershipId
      } : {}),
      ...(organizationId ? {
        "Imdhealth-Organization": organizationId
      } : {}),
      ...(kioskId ? {
        "Imdhealth-Kiosk-Id": kioskId
      } : {}),
      ...(kioskLocationId ? {
        "Imdhealth-Kiosk-Location-Id": kioskLocationId
      } : {}),
      // Session Headers. These are set for every invocation of an app
      ...(sessionHeaders.analyticsSessionId ? {
        "Imdhealth-Session-Id": sessionHeaders.analyticsSessionId
      } : {}),
      ...(sessionHeaders.patientSessionId ? {
        "Imdhealth-Patient-Session-Id": sessionHeaders.patientSessionId
      } : {}),
      ...(locale ? {
        "Accept-Language": locale
      } : {})
    });
  }, [backendState, sessionHeaders, membershipId, organizationId, locale, kioskId, kioskLocationId]); // Every time the backend changes, refetch the location data

  useEffect(() => cancelable(backendState.currentBackend.get("whereami")).then(result => {
    setWhereamiResult(result.data);
    setFetchState({
      busy: false,
      fetched: true
    });
  }).catch(error => {
    Log.error("Failed to fetch backend location", error);
    setFetchState({
      busy: false,
      error,
      fetched: true
    });
  }).cancel, [backendState.currentBackend]); // Overrides the API domain name used during testing
  // Useful for parallel testings where we have a different port number for each core
  // For safety, it will only use the test API if the app is in dev or test mode using locahost API.

  useEffect(() => {
    const currentApiUrl = backendState.currentBackend.defaults.baseURL;
    if (!testApiDomainName) return;
    if (!currentApiUrl?.match(/^http:\/\/localhost:/)) return;
    if (currentApiUrl === testApiDomainName) return;
    setBackendState(makeBackend(testApiDomainName, backendState));
  }, [backendState, testApiDomainName]); // Ensure that the system is using the API that matches the app URL

  useEffect(() => {
    // Test mode has it's own backend logic, so don't track the host URL
    if (Config.IS_TEST) return undefined;

    if (whereamiResult?.supported) {
      const currentRegion = whereamiResult.current;
      const desiredRegion = whereamiResult?.supported.find(supportedRegion => url.host.endsWith(supportedRegion.regionalAppDomainPrefix));

      if (desiredRegion && desiredRegion.regionalAppDomainPrefix !== currentRegion.regionalAppDomainPrefix) {
        setFetchState({
          busy: true,
          fetched: false
        });
        const newBackend = makeBackend(desiredRegion.regionalApiDomainName, backendState);
        return cancelable(newBackend.currentBackend.get("whereami")).then(result => {
          setWhereamiResult(result.data);
          setBackendState(newBackend);
          setFetchState({
            busy: false,
            fetched: true
          });
        }).catch(error => {
          Log.error("Failed to fetch backend location", error);
          setFetchState({
            busy: false,
            error,
            fetched: true
          });
        }).cancel;
      }
    }

    return undefined;
  }, [backendState, url.host, whereamiResult]); // Lets the region picker change the region that this backend is tied do

  const changeRegion = useCallback(region => {
    // Test mode has it's own backend logic, so don't track the region URL
    if (Config.IS_TEST) return;
    const desiredRegion = whereamiResult?.supported.find(supportedRegion => supportedRegion.region === region) || whereamiResult?.current;

    if (desiredRegion) {
      setBackendState(makeBackend(desiredRegion.regionalApiDomainName, backendState));
    }
  }, [backendState, whereamiResult]); // Creates a new instance of the current backend config to force a reload

  const handleClearError = useCallback(() => {
    setFetchState(currentFetchState => ({ ...currentFetchState,
      error: undefined,
      fetched: false
    })); // This creates a copy of the backend state to restart a whereami fetch

    setBackendState(makeBackend(backendState.currentBackend.defaults.baseURL, backendState));
  }, [backendState]); // Set up the app context that gets passed down

  const appBackendContext = useMemo(() => whereamiResult ? { ...backendState,
    changeRegion,
    persistentHeaders: {
      membershipId,
      organizationId
    },
    setSessionHeader,
    whereamiResult
  } : undefined, [whereamiResult, backendState, changeRegion, membershipId, organizationId, setSessionHeader]);

  if (!fetchState.fetched) {
    return <CpAppLoading />;
  } else if (fetchState.error) {
    return <>
        {
        /* Allows us to know when the Cx finished loading during capybara tests */
      }
        <View testID="CxAppBackendError" />
        <View testID="CxAppBackendLoaded" />
        <CpAppLoadingError clearError={handleClearError} />
      </>;
  } else {
    return <>
        <View testID="CxAppBackendLoaded" />
        <AppBackendContext.Provider value={appBackendContext}>
          {children}
        </AppBackendContext.Provider>
      </>;
  }
};

export default memo(CxAppBackend);