import _styled2 from "styled-components";
import _styled from "styled-components";
import React from "react";
import CpItem from "components/common/CpItem";
import PrIcon, { IconName } from "components/primitives/PrIcon";
import PrText from "components/primitives/PrText";
import useAppTheme from "hooks/useAppTheme";
import { getNameVariant } from "services/Graphql";
import { css } from "services/Theme";
const localStyles = {
  item: css`
    overflow-y: hidden;
  `,
  selected: css`
    background-color: ${({
    theme
  }) => theme.brandColors.nodeNavigatorSelected};
    border: 0 solid ${({
    theme
  }) => theme.brandColors.secondary};
    border-left-width: 2px;
  `,
  title: css`
    font-family: ${({
    theme
  }) => theme.fontFamilies.heading};
    font-size: ${({
    theme
  }) => theme.fontSize("small")};
  `
};
const icons = {
  Aperçu: IconName.iconConsultation,
  Comprendre: IconName.iconUnderstanding,
  "Diagnosis & Treatment": IconName.iconDiagnose,
  "Diagnostic et traitement": IconName.iconDiagnose,
  "Diagnóstico y tratamiento": IconName.iconDiagnose,
  "Diagramas de apoyo": IconName.iconDiagrams,
  "Diagrammes de soutien": IconName.iconDiagrams,
  Entendiendo: IconName.iconUnderstanding,
  "Estilo de vida": IconName.iconHealthyLifestyle,
  "Health & Wellness": IconName.iconMindfulness,
  "Herramientas profesionales": IconName.iconStethoscope,
  Lifestyle: IconName.iconHealthyLifestyle,
  Medicamentos: IconName.iconTreatment,
  Medications: IconName.iconTreatment,
  "Mode de vie": IconName.iconHealthyLifestyle,
  Médicaments: IconName.iconTreatment,
  "Outils professionnels": IconName.iconStethoscope,
  Overview: IconName.iconConsultation,
  "Professional Tools": IconName.iconStethoscope,
  "Related Topics": IconName.iconRelated,
  Resumen: IconName.iconConsultation,
  "Salud y Bienestar": IconName.iconMindfulness,
  "Santé et bien-être": IconName.iconMindfulness,
  "Sujets connexes": IconName.iconRelated,
  "Supporting Diagrams": IconName.iconDiagrams,
  "Temas relacionados": IconName.iconRelated,
  Understanding: IconName.iconUnderstanding,
  Videos: IconName.video,
  Vidéos: IconName.video
};

/**
 * An item in CpTopicTableOfContents
 */
const CpTopicTableOfContentsItem = ({
  isSelected,
  node,
  onPress,
  style
}) => {
  const {
    brandColors
  } = useAppTheme();
  const title = getNameVariant(node, ["short", "default"]);
  const icon = icons[title || ""] ?? IconName.iconPatientSession;
  const selectedColor = isSelected ? brandColors.primary : brandColors.nodeNavigatorIcon;
  return <_StyledCpItem left={<PrIcon fill={selectedColor} icon={icon} size={28} />} onPressCallback={onPress} onPressValue={node} style={style} testID={`CpTopicTableOfContentsItem-${node.revisionId}`} $_css={[localStyles.item, isSelected ? localStyles.selected : undefined]}>
      <_StyledPrText children={title} $_css2={[localStyles.title, css`
            color: ${selectedColor};
          `]} />
    </_StyledCpItem>;
};

export default CpTopicTableOfContentsItem;

var _StyledCpItem = _styled(CpItem).withConfig({
  displayName: "CpTopicTableOfContentsItem___StyledCpItem",
  componentId: "sc-ofnjxh-0"
})(["", ""], p => p.$_css);

var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CpTopicTableOfContentsItem___StyledPrText",
  componentId: "sc-ofnjxh-1"
})(["", ""], p => p.$_css2);